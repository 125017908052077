import React, { useState, useEffect} from 'react';
import { useAppSelector } from './hooks';
import Loader from './components/Loader/Loader';
import Navigator from './components/Navigator/Navigator';
import Portfolio from './screens/Portfolio/Portfolio';
import Contact from './screens/Contact/Contact';
import About from './screens/About/About';
import './App.css';

const sleep = (ms:number) =>{
  return new Promise(resolve => setTimeout(resolve, ms));
}

function App() {

  const screenName = useAppSelector(state => state.screenManager.screen);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth)

  const renderProperScreen = () => {
    if(screenName === 'about') {
      return <About />
    } else if(screenName === 'contact') {
      return <Contact />
    }
    return <Portfolio />
  }

	useEffect(()=>{
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);
		return () => {
		  window.removeEventListener("resize", handleResizeWindow);
		};
	});

  useEffect(()=>{
    async function waitForChange() {
      setLoading(true);
      await sleep(700);
      setLoading(false);
    }
    waitForChange();
  },[screenName])

  const mainPageSection2JSX = (
    <div className="main-container-section-2">
      <div className='main-container-photography-grid'>
        {loading ? <Loader />:renderProperScreen()}
      </div>
      <div className="section-2-border"></div>
    </div>
  );

  const mobileJSX = (
    <div className="mobile-container-view">
        {renderProperScreen()}
    </div>
  )

  return (
    <div className="main-container">
      <div className="main-container-content">
        {width >= 750? (<div className="section-1-border"></div>):null}
        <div className="main-container-section-1">
          <div className="main-container-title">
            <div className="main-container-name">
              <span className="name-header">brian</span>
            </div>
            <div className="main-container-name">
              <span className="name-header underline">aguilar</span>
            </div>
            <div className="main-container-quote">
              <span className="quote-text">Photographier c’est mettre sur la meme ligne de mire la tete, l’oeil et le coeur</span>
            </div>
          </div>
          <Navigator />
        </div>
        {width >= 750 ? mainPageSection2JSX: loading ? <div className='app-loader'><Loader/></div>:mobileJSX}
      </div>
    </div>
  );
}

export default App;
