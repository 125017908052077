import React from 'react';
import SelfPortrait from '../../assets/imgs/Self Portrait.jpg'
import './About.css';

export default function About() {
	return (
		<div className="about-container">
			<div className="about-content-container">
				<div className="about-content">
					<div className="about-content-header-container">
						<span className="about-header-text">About</span>
					</div>
					<div className="about-content-desc-container">
						<span className="about-desc-text">
							Hey, I'm Brian Aguilar and I am freelance photographer based out of New York. <br/><br/>
							It has always been a passion of mine to capture precious memories that can be 
							remembered til the end of time. My work is primarily geared towards Portrait & 
							Event Photography as I love to dive deep into the reality of others. Not only do I endeavor 
							to understand and create connections with others through my lens, but I also aspire to help my 
							clients express their stories through the art of photography.<br/><br/>
							In order to capture the best moments, I develop a sincere connection with my clients,
							as well as maintain a professional and heartwarming attitude when working. <br/><br/>

						</span>
					</div>
				</div>
			</div>
			<div className="about-portrait-container">
				<img
					src={SelfPortrait}
					alt={"Brian Aguilar"}
					className='self-portrait'
				/>
			</div>
		</div>
	)
}