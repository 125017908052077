import React, { useState } from 'react';
import ArrowIcon from '../../assets/imgs/arrow.png';
import XIcon from '../../assets/imgs/x.png';
import { useAppSelector } from '../../hooks';
import './Slideshow.css';

interface SlideShowProps {
	selectedIndex:number,
	setShowslideShow: (value:boolean)=>void
}

export default function SlideShow({selectedIndex, setShowslideShow}: SlideShowProps) {

	const images = useAppSelector(state=> state.screenManager.images);
	const [index, setIndex] = useState(selectedIndex);

	const nextImg = ()=> {
		const length = images.length;
		if(index !== length-1) {
			setIndex(index+1)
		}
	}

	const prevImg = () => {
		if(index !== 0) {
			setIndex(index-1);
		}
	}

	return (
		<div className="slideshow-container">
			<div className="slideshow-content-container">
				<div className="slideshow-header-container">
					<div className="slideshow-exit-button-container">
						<img
							className="x-icon-button"
							onClick={()=> setShowslideShow(false)}
							src={XIcon}
							alt="Exit"
							width={20}
						/>
					</div>
				</div>
				<div className="slideshow-body-container">
					<div className="slideshow-left-button-container">
						<span
							onClick={()=>prevImg()} 
							className="arrow-button-text"
						>
							<img
								src={ArrowIcon}
								alt="right-arrow"
								width={30}
								style={{transform:'rotate(180deg)'}}
							/>
						</span>
					</div>
					<div className="slideshow-image-container">
						<img
							src={images[index]}
							alt="selectedImage"
							className="slideshow-img"
						/>
					</div>
					<div
						className="slideshow-right-button-container"
					>
						<span 
							onClick={()=>nextImg()}
							className="arrow-button-text"
						>
							<img
								src={ArrowIcon}
								alt="right-arrow"
								width={30}
							/>
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}