import { configureStore } from "@reduxjs/toolkit";
import screenManagerReducer from "./slices/ScreenManagerSlice";

export const store = configureStore({
	reducer: {
		screenManager: screenManagerReducer
	}
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;