import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { updateScreen } from '../../redux/slices/ScreenManagerSlice';
import XIcon from '../../assets/imgs/x.png'
import './Navigator.css';

export default function Navigator() {

	const dispatch = useAppDispatch();
	const pages = ['portraits','events','landscape','street','about','contact'];
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [width, setWidth] = useState(window.innerWidth)
	const screenName = useAppSelector(state => state.screenManager.screen);

	useEffect(()=>{
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);
		return () => {
		  window.removeEventListener("resize", handleResizeWindow);
		};
	});

	const displayProperScreen = (link: string)=>{
		dispatch(updateScreen(link));
	}

	const displayProperScreenMobile = (link:string) => {
		dispatch(updateScreen(link));
		setShowMobileMenu(false);
	}

	const renderWebLinks = ()=> {
		return pages.map((link)=>{
			const className = screenName.includes(link) ?
				"navigation-link-container link-underline":"navigation-link-container";
			return (
				<div 
					key={link}
					className={className}
					style={link === 'about' ?{marginTop:'25px'}:{}}
				>
					<span 
						className='navigation-link'
						onClick={()=> displayProperScreen(link)}
					>
						{link}
					</span>
				</div>
			)
		})
	}

	const renderMobileLinks = ()=> {
		return pages.map((link)=>{
			const className = screenName.includes(link) ?
				"navigation-link-container link-underline":"navigation-link-container";
			return (
				<div 
					key={link}
					className={className}
					style={link === 'about' ?{marginTop:'20px'}:{}}
				>
					<span 
						className='navigation-link'
						onClick={()=> displayProperScreenMobile(link)}
					>
						{link}
					</span>
				</div>
			)
		})
	}

	const mobileNavigatorDropdownJSX = (
		<div className="mobile-menu-overlay">
			<div className="mobile-menu-container slide-left">
				<div className="mobile-menu-links-container">
					<div className="mobile-x-button-icon-container">
						<img
							onClick={()=> setShowMobileMenu(false)}
							className='mobile-nav-x'
							src={XIcon}
							width={20}
							alt="X"
						/>
					</div>
					<div className="mobile-menu-links-list">
						{renderMobileLinks()}
					</div>
				</div>
			</div>
		</div>
	)

	const webNavigatorJSX = (
		<div className='navigator-container'>
			{renderWebLinks()}
		</div>
	)
	
	const mobileNavigatorJSX = (
		<div className='navigator-container-mobile'>
			{showMobileMenu ? mobileNavigatorDropdownJSX:null}
			<div className="navigation-mobile-link-container">
				<span 
					className='navigation-link'
					onClick={()=> setShowMobileMenu(true)}
				>
					menu
				</span>
			</div>
		</div>
	)

	return width >= 750 ? webNavigatorJSX: mobileNavigatorJSX
}