import React from 'react';
import './Contact.css';

export default function Contact() {
	return (
		<div className="contact-container">
			<div className="contact-content-container">
				<div className="about-content-header-container">
					<span className="about-header-text">Contact</span>
				</div>
				<div className="contact-body-container">
					<div className="email-contact-container">
						<label>Email:</label>
						<a href="mailto:baguilar0398@gmail.com" className="contact-text">
							baguilar0398@gmail.com
						</a>
					</div>
					<div className="instagram-contact-container">
						<label>Instagram:</label>
						<a href="https://www.instagram.com/k.enji_" className="contact-text">
							@k.enji_
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}