export const PORTRAITS: string[] = [
	require('../assets/portfolio/portaits/DSC00257-2.PNG'),
	require('../assets/portfolio/portaits/DSC00924.PNG'),
	require('../assets/portfolio/portaits/DSC01486.png'),
	require('../assets/portfolio/portaits/DSC01120.PNG'),
	require('../assets/portfolio/portaits/DSC02203.jpg'),
	require('../assets/portfolio/portaits/_MG_8693-2.jpg'),
	require('../assets/portfolio/portaits/Paulino.jpg'),
	require('../assets/portfolio/portaits/Alice.jpg'),
	require('../assets/portfolio/portaits/Clara.jpg'),
	require('../assets/portfolio/portaits/Sydney_Roosevelt_Island_2018.jpg'),
	require('../assets/portfolio/portaits/PoP_2.jpg'),
	require('../assets/portfolio/portaits/Justine.jpg'),
	require('../assets/portfolio/portaits/_MG_5236.jpg'),
	require('../assets/portfolio/portaits/_MG_4528.jpg'),
	require('../assets/portfolio/portaits/_MG_6736.jpg'),
	require('../assets/portfolio/portaits//20201106-_DSF6863.jpg'),
	require('../assets/portfolio/portaits/_MG_5782.jpg'),
	require('../assets/portfolio/portaits/IMG_3624.jpg'),
	require('../assets/portfolio/portaits/_MG_5229.jpg'),
	require('../assets/portfolio/portaits/20201106-_DSF6757.jpg'),
	require('../assets/portfolio/portaits/_MG_5775.jpg'),
	require('../assets/portfolio/portaits/_MG_7125.jpg'),
	require('../assets/portfolio/portaits/_MG_4056.jpg'),
	require('../assets/portfolio/portaits/_MG_4516.jpg'),
	require('../assets/portfolio/portaits/_MG_1512.jpg'),
	require('../assets/portfolio/portaits/_MG_7261.jpg'),
	require('../assets/portfolio/portaits/_MG_6051.jpg'),
]

export const EVENTS: string[] = [
	require('../assets/portfolio/events/DSC05360.jpg'),
	require('../assets/portfolio/events/DSC05446.jpg'),
	require('../assets/portfolio/events/DSC02018.jpg'),
	require('../assets/portfolio/events/DSC03798.jpg'),
	require('../assets/portfolio/events/_MG_8620.jpg'),
	require('../assets/portfolio/events/IMG_5196.jpg'),
	require('../assets/portfolio/events/DSC02108.jpg'),
	require('../assets/portfolio/events/DSC04977.jpg'),
	require('../assets/portfolio/events/DSC05415.jpg'),
	require('../assets/portfolio/events/DSC04012.jpg'),
	require('../assets/portfolio/events/IMG_5317.jpg'),
	require('../assets/portfolio/events/_MG_8563.jpg'),
	require('../assets/portfolio/events/DSC05023.jpg'),
	require('../assets/portfolio/events/IMG_5906.jpg'),
	require('../assets/portfolio/events/_MG_8647.jpg'),
	require('../assets/portfolio/events/DSC02008.jpg'),
	require('../assets/portfolio/events/IMG_5444.jpg'),
	require('../assets/portfolio/events/DSC03888.jpg'),
	require('../assets/portfolio/events/DSC02044.jpg'),
	require('../assets/portfolio/events/_MG_8552.jpg'),
	require('../assets/portfolio/events/DSC05641.jpg'),
	require('../assets/portfolio/events/DSC03951.jpg'),
	require('../assets/portfolio/events/_MG_8613.jpg'),
	require('../assets/portfolio/events/DSC04931.jpg'),
	require('../assets/portfolio/events/DSC02006.jpg'),
	require('../assets/portfolio/events/_MG_8492.jpg'),
	require('../assets/portfolio/events/DSC03859.jpg'),
	require('../assets/portfolio/events/IMG_5263.jpg'),
	require('../assets/portfolio/events/DSC05396.jpg'),
	require('../assets/portfolio/events/_MG_8530.jpg'),
	require('../assets/portfolio/events/DSC04948.jpg'),
	require('../assets/portfolio/events/DSC02053.jpg'),
	require('../assets/portfolio/events/_MG_8508.jpg'),
	require('../assets/portfolio/events/_MG_8631.jpg'),
	require('../assets/portfolio/events/DSC02032.jpg'),
	require('../assets/portfolio/events/IMG_5175.jpg'),
	require('../assets/portfolio/events/DSC05521.jpg'),
]


export const LANDSCAPE: string[] = [
	require('../assets/portfolio/landscape/DUMBO_2.jpg'),
	require('../assets/portfolio/landscape/Grand.jpg'),
	require('../assets/portfolio/landscape/Oculus.jpg'),
	require('../assets/portfolio/landscape/Times.jpg'),
	require('../assets/portfolio/landscape/IMG_2394.jpg'),
	require('../assets/portfolio/landscape/IMG_6360.jpg'),
	require('../assets/portfolio/landscape/_MG_0707.jpg'),
	require('../assets/portfolio/landscape/_MG_0786.jpg'),
	require('../assets/portfolio/landscape/DUMBO.jpg')
]

export const STREET: string[] = [
	require('../assets/portfolio/street/South_Seaport.jpg'),
	require('../assets/portfolio/street/Bryant_2.jpg'),
	require('../assets/portfolio/street/Chelsea_1.jpg'),
	require('../assets/portfolio/street/Grand_2.jpg'),
	require('../assets/portfolio/street/Central_2.jpg'),
	require('../assets/portfolio/street/Chelsea_4.jpg'),
	require('../assets/portfolio/street/Flushing.jpg'),
	require('../assets/portfolio/street/Grand_1.jpg'),
	require('../assets/portfolio/street/NYU_1.jpg'),
	require('../assets/portfolio/street/NYU_2.jpg'),
	require('../assets/portfolio/street/Brooklyn.jpg'),
	require('../assets/portfolio/street/Central_1.jpg'),
	require('../assets/portfolio/street/Pier.jpg'),
	require('../assets/portfolio/street/Union.jpg'),
]