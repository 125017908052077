import React from 'react';
import LoaderGIF from '../../assets/imgs/loader.gif';
import './Loader.css';

export default function Loader() {
	return (
		<div className="loader-container">
			<img
				src={LoaderGIF}
				alt="Loading..."
			/>
		</div>
	)
}