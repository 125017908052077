import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EVENTS, LANDSCAPE, PORTRAITS, STREET } from '../../constants/imagesources';

const imagesMap = {
	'portraits':PORTRAITS,
	'landscape':LANDSCAPE,
	'events':EVENTS,
	'street':STREET
}

interface ScreenManagerState {
	screen: string,
	images: string[]
}

const initialState: ScreenManagerState = {
	screen:'/portraits',
	images:PORTRAITS
}

export const screenManagerSlice = createSlice({
	name:'screenManager',
	initialState,
	reducers: {
		updateScreen: (state, action: PayloadAction<string>)=>{
			const screenName = action.payload;
			state.screen = screenName;
			if(imagesMap[screenName] !== undefined) {
				state.images = imagesMap[screenName];
			}
		}
	}
})

export const { updateScreen } = screenManagerSlice.actions;
export default screenManagerSlice.reducer;