import React, { useState } from 'react';
import SlideShow from '../../components/Slideshow/Slideshow';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useAppSelector } from '../../hooks';
import './Portfolio.css';

export default function Portfolio() {

	const portfolioImages = useAppSelector(state => state.screenManager.images);
	const [showSlideshow, setShowSlideshow] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleImageOnClick = (index: number) => {
		setSelectedIndex(index);
		setShowSlideshow(true);
	}

	const renderImages = (images: string[]) => {
		return images.map((src,index)=>{
			return (
				<div 
					className="image-container"
					 key={index}
					 onClick={()=>handleImageOnClick(index)}
				>
					<img
						width={'100%'}
						src={src}
						alt={index+""}
					/>
				</div>
			)
		})
	}

	return (
		<div className='portfolio-container'>
			{ 
				showSlideshow ? 
					<SlideShow 
						selectedIndex={selectedIndex} 
						setShowslideShow={()=> setShowSlideshow(false)}
					/>: 
					null
			}
				<ResponsiveMasonry
					columnsCountBreakPoints={{350: 1, 750: 2}}
				>
				<Masonry>
					{renderImages(portfolioImages)}
				</Masonry>
			</ResponsiveMasonry>
		</div>
	)
}